html, body {
	font-size: 16px;
	background-color: #ffffff;
	margin: 0;
	width: 100%;
	font-family: Arial;
}

.pomad-placement {
	margin-bottom: 10px;
}

.pomad-placement[data-pmd-placement-loaded="1"].show-text::before {
	 display: block;
	 content: 'Advertisement';
	 font-family: Helvetica, Arial, sans-serif;
	 font-size: 10px;
	 color: #9e9e9e;
	 text-transform: uppercase;
	 letter-spacing: normal;
	 text-align: center;
}

.download-btn {
	margin-top: 25px;
}

.hide {
	display: none;
}

.left-side-wrapper, .right-side-wrapper {
	display: inline-block;
}

.pomad-placement {
    text-align: center;
}

.pomad-placement > div {
	display: inline-block;
}

#fixed-mobile-menu {
	position: fixed;
	width: 100%;
	text-align: right;
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	height: 3rem;
	right: 0rem;
	top: 0rem;
	display: inline-block;
	z-index: 999;
	/*background: rgba(255,255,255,0.8);*/
}

.mobile-menu__content {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: #f0f0f0;
	margin-top: 0;
	padding-top: 4.5rem;
	padding-bottom: 4.5rem;
	font-size: 1.2rem;
	overflow-y: scroll;
	overflow-x: hidden;
}

.mobile-menu__content li {
	list-style: none;
	padding-bottom: 2rem;
}

.mobile-menu__content li a {
	text-decoration: none;
	color: black;
}

.mobile-menu__content li:first-child a {
	list-style: none;
	color: green;
}


html * {
	box-sizing: border-box;
}
.red {
	background-color: red;
}

.blue {
	background-color: blue;
}


#left-nav-footer {
	display: inline-block;
	width: 100%;
}

#left-nav-footer-placement {
	position: sticky;
	position: -webkit-sticky;
	margin-top: 1rem;
	top: 1rem;
	/*width: 300px;*/
	/*height: 600px;*/
}

.clearfix {
	overflow: auto;
	zoom: 1;
}

.menu {
	position: relative;
	max-width: 360px;
	width: 300px;
	display: inline-block;
	float: left;
}

#content {
	display: inline-block;
}

.content {
	display: inline-block;
	max-width: calc(100% - 300px);
	padding: 0 0 0 2rem;
	float: left;
}

ul {
	margin: 0;
}

.sub-list {
	padding-left: 16px;
	list-style-type: lower-alpha;
}

a:link {
	color: #0000ff;
	text-decoration: underline;
}
a:active {
	color: #0000ff;
	text-decoration: underline;
}
a:visited {
	color: green;
	text-decoration: underline;
}
a:hover {
	color: red;
	text-decoration: underline;
}

#container {
	max-width: 1280px;
	margin: 0 auto;
	padding: 0 1rem;
}
#masthead {
	border: #d4d4d4 2px solid;
	border-radius: 8px;
	padding: 1em;
	background-color: #f0f0f0;
	color: grey;
	font-size: x-large;
	margin: 10px auto;
}
#searchbox {
	float: right;
	margin-right: 5em;
}
.rectangle {
	float: left;
	font-size: 15px;
	padding: 15px 15px 0px 0px
}
.rectangle2 {
	padding: 25px 0px 0px 60px
}
.rtsmallbanner {
	float: left;
	padding: 0px 1px 1px 0px;
}
.fb-like {
	padding: 0 2em;
}
.support {
	font-size: x-small;
	margin: -1.7em 0 0 12.9em;
}
.toplinkunit {
	margin: -.82em 0 0 13em;
}
#workbooks {
	font-size: 10pt;
}
#leftNav {
	font-size: 10pt;
	margin-top: 10px;
	clear: both;
}

#footer {
	text-align: center;
	font-size: 9pt;
	clear: both;
	border: #d4d4d4 2px solid;
	border-radius: 8px;
	padding: 1em;
	background-color: #f0f0f0;
}
/* Masthead Styles */
#masthead h1 {
	color: #3d6c87;
	margin: 0.5rem 0;
	position: relative;
	right: 3px;
	font-size: 1.8rem;
	display: inline-block;
}
#masthead h3 {
	color: #5f8ea9;
}
#masthead img {
	border: 0;
}
/* Navigation Styles */
.navigation ul {
	list-style-type: none;
	max-width: 100%;
	display: block;
	margin: 0;
	border: #d4d4d4 2px solid;
	border-radius: 8px;
	background-color: #f0f0f0;
	padding: 1rem 1.25rem;
}
.navigation li:first-child {
	margin: 0;
}
.navigation li {
	display: block;
	margin: 0;
	padding: 0;
}
.navigation a {
	text-decoration: none;
	display: block;
	padding: 5px;
	font-size: 16px;
}
.navigation a:hover {
	color: red;
	text-decoration: none;
	background-color: #fff;
}
/* Content Styles */

.link {
	margin-left: 30px;
	font-size: 14px;
	line-height: 170%;
	list-style: square;
}
.verbslink {
	margin-left: 30px;
	font-size: 14px;
	line-height: 160%;
	list-style: square;
}
.linkheader {
	margin-top: 10px;
	margin-left: -15px;
	font-size: 15px;
	line-height: 180%;
}
#content h1 {
	font-size: x-large;
	font-weight: 700;
	background-image: url('../images/logo.png');
	background-position: left;
	background-repeat: no-repeat;
	background-size: 40px 37px;
	text-indent: 50px;
	padding-top: 10px;
}
#content h2 {
	font-size: 15px;
	font-weight: normal;
}
#content h3 {
	font-size: large;
	font-weight: 700;
}
#content h4 {
	font-size: 15px;
}
#content h5 {
	font-size: large;
	font-weight: 700;
}
#content h6 {
	font-size: large;
	font-weight: 700;
}
#content img {
	border: 0;
	margin: 10px 20px auto 40px;
	vertical-align: middle;
}
li.column1 { margin-left: 0em; }
li.column2 { margin-left: 10em; }
li.column3 { margin-left: 20em; }

/* Footer Styles */
#footer p {
	margin-top: 10px
}
#footer a {
	color: #0000ff;
	text-decoration: underline;
}
#footer a:hover {
	color: red;
	text-decoration: none;
}

@media only screen and (max-width: 1599px) {
	#container {
		margin: 0 auto;
	}

	.left-side-wrapper, .right-side-wrapper {
		display: none;
	}
}
@media only screen and (min-width:1023px){
	#top-placement {
		min-width: 728px;
		min-height: 262px;
	}
}


@media only screen and (max-width:1024px) and (min-width: 910px){

	.menu {
		max-width: 200px;
		width: auto;
	}

	.content {
		max-width: calc(100% - 200px);
	}


}

@media only screen and (max-width:910px) {

	#left-menu {
		display: none;
	}



	#footer-placement {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		text-align: center;
		z-index: 9999;
		/*height: 100px;*/
		/*background-color: blue;*/
	}

	#top-placement {
		min-height: 50px;
		max-height: 250px;
	}

	#footer {
		padding-bottom: 60px;
		border: none;
		border-radius: 0;
	}

	.content ul {
		padding-left: 0;
		margin-left: 0;
	}

	#masthead {
		font-size: 1rem;
	}

	.navigation ul.nav-items-container {
		display: none;
	}

	#container {
		padding: 0;
	}

	#content {
		max-width: 100%;
		overflow-x: hidden;
		padding: 0 0.5rem;
	}

	#content img {
		max-width: 100%;
		width: auto;
		height: auto;
		margin: 0;
	}
	.content {
		margin: 0;
		padding: 0.5rem;
		width: auto;
		max-width: 100%;
	}

	.menu {
		position: relative;
		max-width: none;
		min-width: 100%;
		display: inline-block;
		float: left;
	}

	.pull-right {
		float: right;
	}

	#workbooks {
		position: inherit;
  		float: none;
  		width: auto;
  		margin-left: auto;
	}
	#leftNav {
		position: relative;
		max-width: 100%;
		width: 100%;
		display: inline-block;
		float: none;
	}

	.rectangle2 {
		padding: 0
	}
	#content h1 {
		background-image: none;
		text-indent: 0;
	}
	#content > div.content > h1 > a {
		display: none
	}

	.fb-like {
		padding: 0;
		margin: 0;
	}

	#masthead h1 {
		font-size: 1.2rem;
		display: block;
		position: static;
	}

	#masthead {
		border: none;
		border-radius: 0;
		margin: 0;
	}

	.icon-close {
		position: relative;
		top: 4px;
		right: 4px;
	}
}
